import PropTypes from 'prop-types';
import Icon from './Icon';

export default function IcSettings03({variant, className, stroke, fill}) {
  const VARIANTS = {
    multi: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m14.5 19c0 .82845-.67155 1.5-1.5 1.5s-1.5-.67155-1.5-1.5.67155-1.5 1.5-1.5 1.5.67155 1.5 1.5z"
          fill={fill}
        />
        <path
          d="m3 18c-.554 0-1 .446-1 1s.446 1 1 1h8.8828c-.2377-.26542-.38281-.61564-.38281-1s.14511-.73458.38281-1h-8.8828zm11.117 0c.2377.26542.38281.61564.38281 1s-.14511.73458-.38281 1h6.8828c.554 0 1-.446 1-1s-.446-1-1-1h-6.8828z"
          fill={stroke}
        />
        <path
          d="m13 20c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1zm0 2c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3z"
          fill={stroke}
          fillRule="evenodd"
        />
        <path
          d="m9.5 12c0 .82845-.67158 1.5-1.5 1.5s-1.5-.67155-1.5-1.5.67158-1.5 1.5-1.5 1.5.67155 1.5 1.5z"
          fill={fill}
        />
        <path
          d="m3 11c-.554 0-1 .446-1 1s.446 1 1 1h3.8828c-.23771-.26542-.38281-.61564-.38281-1s.1451-.73458.38281-1h-3.8828zm6.1172 0c.23771.26542.38281.61564.38281 1s-.1451.73458-.38281 1h11.883c.554 0 1-.446 1-1s-.446-1-1-1h-11.883z"
          fill={stroke}
        />
        <path
          d="m8 13c.55228 0 1-.4477 1-1s-.44772-1-1-1-1 .4477-1 1 .44772 1 1 1zm0 2c1.6568 0 3-1.3431 3-3s-1.3432-3-3-3-3 1.3431-3 3 1.3432 3 3 3z"
          fill={stroke}
          fillRule="evenodd"
        />
        <path
          d="m17.5 5c0 .82842-.67155 1.5-1.5 1.5s-1.5-.67158-1.5-1.5.67155-1.5 1.5-1.5 1.5.67158 1.5 1.5z"
          fill={fill}
        />
        <path
          d="m3 4c-.554 0-1 .446-1 1s.446 1 1 1h11.883c-.2377-.26542-.38281-.61566-.38281-1s.14511-.73458.38281-1h-11.883zm14.117 0c.2377.26542.38281.61566.38281 1s-.14511.73458-.38281 1h3.8828c.554 0 1-.446 1-1s-.446-1-1-1h-3.8828z"
          fill={stroke}
        />
        <path
          d="m16 6c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1zm0 2c1.6569 0 3-1.3432 3-3s-1.3431-3-3-3-3 1.3432-3 3 1.3431 3 3 3z"
          fill={stroke}
          fillRule="evenodd"
        />
      </svg>
    ),
    single: (
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
        <path
          d="m14.5 19c0 .82845-.67155 1.5-1.5 1.5s-1.5-.67155-1.5-1.5.67155-1.5 1.5-1.5 1.5.67155 1.5 1.5z"
          fill="#E8EDF4"
        />
        <path d="m3 18c-.554 0-1 .446-1 1s.446 1 1 1h8.8828c-.2377-.26542-.38281-.61564-.38281-1s.14511-.73458.38281-1h-8.8828zm11.117 0c.2377.26542.38281.61564.38281 1s-.14511.73458-.38281 1h6.8828c.554 0 1-.446 1-1s-.446-1-1-1h-6.8828z" />
        <path
          d="m13 20c.5523 0 1-.4477 1-1s-.4477-1-1-1-1 .4477-1 1 .4477 1 1 1zm0 2c1.6569 0 3-1.3431 3-3s-1.3431-3-3-3-3 1.3431-3 3 1.3431 3 3 3z"
          fillRule="evenodd"
        />
        <path
          d="m9.5 12c0 .82845-.67158 1.5-1.5 1.5s-1.5-.67155-1.5-1.5.67158-1.5 1.5-1.5 1.5.67155 1.5 1.5z"
          fill="#E8EDF4"
        />
        <path d="m3 11c-.554 0-1 .446-1 1s.446 1 1 1h3.8828c-.23771-.26542-.38281-.61564-.38281-1s.1451-.73458.38281-1h-3.8828zm6.1172 0c.23771.26542.38281.61564.38281 1s-.1451.73458-.38281 1h11.883c.554 0 1-.446 1-1s-.446-1-1-1h-11.883z" />
        <path
          d="m8 13c.55228 0 1-.4477 1-1s-.44772-1-1-1-1 .4477-1 1 .44772 1 1 1zm0 2c1.6568 0 3-1.3431 3-3s-1.3432-3-3-3-3 1.3431-3 3 1.3432 3 3 3z"
          fillRule="evenodd"
        />
        <path
          d="m17.5 5c0 .82842-.67155 1.5-1.5 1.5s-1.5-.67158-1.5-1.5.67155-1.5 1.5-1.5 1.5.67158 1.5 1.5z"
          fill="#E8EDF4"
        />
        <path d="m3 4c-.554 0-1 .446-1 1s.446 1 1 1h11.883c-.2377-.26542-.38281-.61566-.38281-1s.14511-.73458.38281-1h-11.883zm14.117 0c.2377.26542.38281.61566.38281 1s-.14511.73458-.38281 1h3.8828c.554 0 1-.446 1-1s-.446-1-1-1h-3.8828z" />
        <path
          d="m16 6c.5523 0 1-.44772 1-1s-.4477-1-1-1-1 .44772-1 1 .4477 1 1 1zm0 2c1.6569 0 3-1.3432 3-3s-1.3431-3-3-3-3 1.3432-3 3 1.3431 3 3 3z"
          fillRule="evenodd"
        />
      </svg>
    ),
  };

  return (
    <Icon className={className} label="FilterSliders">
      {VARIANTS[variant]}
    </Icon>
  );
}

IcSettings03.propTypes = {
  className: Icon.propTypes.className,
  stroke: PropTypes.string,
  fill: PropTypes.string,
  variant: PropTypes.string,
};

IcSettings03.defaultProps = {
  className: '',
  stroke: '#182C53',
  fill: 'white',
  variant: 'single',
};
